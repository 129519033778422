















import { Component, Mixins, Prop } from "vue-property-decorator";
import TagsManagement from "@/components/library-items/TagsManagement.vue";
import { LibraryTabs } from "@/entity/Library";
import { StateTab } from "@/interfaces";

@Component({
  components: {
    TagsManagement,
  },
})
export default class DictionariesView extends Mixins() {
  activeTab = LibraryTabs.TAGS;

  get tabs(): StateTab[] {
    return [
      {
        name: "tags",
        key: LibraryTabs.TAGS,
      },
    ];
  }

  get showTagsManagement() {
    return this.activeTab === LibraryTabs.TAGS;
  }

  handleTabChange(tabKey: LibraryTabs) {
    this.activeTab = tabKey;
  }
}
