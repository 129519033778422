












































































import { Component, Mixins } from "vue-property-decorator";
import DataTable from "@/components/data-table/DataTable.vue";
import { ITableHeader, TableQuery } from "@/components/data-table/types";
import DialogMixin from "@/mixins/DialogMixin";
import TagForm from "@/components/library-items/TagForm.vue";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import User from "@/entity/User";
import ActionButtonWithTooltip from "@/components/common/ActionButtonWithTooltip.vue";
import Rating from "@/components/Rating.vue";
import { Action } from "vuex-class";
import TagService from "@/services/TagService";
import Tag from "@/entity/Tag";

@Component({
  components: {
    DataTable,
    ActionButtonWithTooltip,
    Rating,
    TagForm,
  },
})
export default class TagsManagement extends Mixins(
  DialogMixin,
  TranslatedEnumListsMixin
) {
  @Action("fetchSubIndustries", { namespace: "selectOptions" })
  fetchSubIndustries!: () => Promise<void>;

  showSkeleton = true;
  options!: TableQuery;
  tags: Tag[] = [];
  itemsLength = 0;
  isFetchingItems = false;
  authorOptions: User[] = [];

  get tableHeaders(): ITableHeader[] {
    return [
      {
        filterType: "text",
        text: this.$tc("name", 1),
        value: "name",
        width: "150px",
      },
      {
        filterType: "select",
        value: "isDeleted",
        filterValue: "isDeleted",
        text: this.$tc("isDeleted", 1),
        options: this.translatedEnums.boolOptions,
        optionText: "name",
        optionValue: "value",
        width: "150px",
      },
      {
        filterType: "date",
        text: this.$tc("date", 1),
        value: "updatedAt",
        width: "150px",
      },
      {
        filterType: "select",
        text: this.$tc("author", 1),
        options: this.authorOptions,
        optionText: "displayName",
        optionValue: "id",
        value: "createdBy",
        filterValue: "createdBy",
        width: "150px",
      },
      {
        text: this.$tc("actions"),
        value: "action",
        width: "80px",
      },
    ];
  }

  async fetchItems() {
    try {
      this.isFetchingItems = true;
      const res = await TagService.find(this.options, true);
      this.tags = res.content;
      this.itemsLength = res.totalItems;
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.projectsList"));
    } finally {
      this.isFetchingItems = false;
    }
  }

  onOptionsChange(options: TableQuery) {
    this.options = options;
    this.fetchItems();
  }

  onFormSuccess(): void {
    this.closeDialog();
    this.fetchItems();
  }

  async handleDeactivate(item: Tag) {
    const didConfirm = await this.$confirm(this.$tc("confirmations.delete"));
    if (!didConfirm) return null;

    await TagService.deactivate(item.id as number);
    await this.fetchItems();
  }

  async onActivate(item: Tag) {
    await TagService.activate(item.id as number);
    await this.fetchItems();
  }

  async created() {
    this.showSkeleton = false;
  }
}
